import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
// import './lib/foundation-explicit-pieces';


$(document).foundation();



$(document).ready(function() {

    if (Foundation.MediaQuery.current !== "small") {
        $("#mquerie-wide").show();
        $("#mquerie-small").hide();
    } else {
        $("#mquerie-wide").hide();
        $("#mquerie-small").show();
    }
    $('#bnieuwboek').hide();

    $("#zoekhetboek").click(function(){
        var search = $("#boek").val();
        // var search2 = $("#boekISBN").val();
        // if (search == "" && search2 == "") {
        if (search == "") {
            alert("Vul wel eerst een boek-kenmerk in: (titel, schrijver, ISBN nummer).");
        } else {
            var url = "";              var img = "";            var title = "";     var subtitle = "";
            var author = "";           var isbn10 = "";         var isbn13 = "";    var uitgever = "";
            var pdate = "";            var tipje = "";          var snippet = "";   var isbn = "";
            var pages = "";            var categ = "";          var meerlezen = ""; var author2 = "";
            var i = 0;                 var vorige = "";         var volgende = "";  var isbn2 = "";
            var snippet2 = "";         var title2 = "";         var img2 = "";
            var link2 = "";            var pages2 = "";         var publisher2 = "";    var pubdate2 = "";
            tipje = "";                var img3 = "";    
    
            $.get("https://www.googleapis.com/books/v1/volumes?q=" +  search , function(response){
    
                console.log(response);
                i = 0;
                url = 0; img = 0; title = 0; subtitle = 0; author = 0; isbn10 = 0; isbn13 = 0; isbn = 0;
                pdate = 0; snippet = 0; pages = 0; categ = 0; meerlezen = 0; uitgever = 0; 
                vorige = 0; volgende = 0; author2 = 0; title2 = 0; isbn2 = 0; snippet2 = 0;  img2 = 0; 
                link2 = 0; pages2 = 0; publisher2 = 0; pubdate2 = 0; img3 = 0; 
    
                // titel
                title=$('<tr><td>Titel: </td><td class="btitel"><input type="text" id="sc_titel" value="' + response.items[i].volumeInfo.title + '" name="boektitel"></td></tr>') ;
                title2 = response.items[i].volumeInfo.title;
                // auteur - schrijver
                if (response.items[i].volumeInfo.authors!=null) { 
                        author = $('<tr><td class="field-container2" id="field_isbn">Auteur: </td><td><input type="text" id="sc_schrijver" value="' + 
                        response.items[i].volumeInfo.authors + '" name="schrijver"></td></tr>');
                        author2 = JSON.stringify(response.items[i].volumeInfo.authors);
                        author2 = response.items[i].volumeInfo.authors;
                    } else {
                        author = $('<tr><td class="field-container2" id="field_isbn">Auteur: </td><td><input type="text" id="sc_schrijver" value="Onbekende auteur" name="schrijver"></td></tr>');
                        author2 = 'Onbekende auteur';
                }
                // ISBN Nummers
                if (response.items[i].volumeInfo.industryIdentifiers!=null) { 
                    if (response.items[i].volumeInfo.industryIdentifiers[0]!=null) { 
                        if (response.items[i].volumeInfo.industryIdentifiers[0].identifier!=null) {
                                isbn10=$('<div class="bisbn10">' + response.items[i].volumeInfo.industryIdentifiers[0].identifier  + '</div>');
                                isbn = $('<tr><td class="field-container2" id="field_isbn">ISBN: </td><td><input type="text" id="sc_isbn" value="' + 
                                response.items[i].volumeInfo.industryIdentifiers[0].identifier + '" name="isbn"></td></tr>');
                                isbn2 = response.items[i].volumeInfo.industryIdentifiers[0].identifier;
                        }
                    }
                    if (response.items[i].volumeInfo.industryIdentifiers[1]!=null) { 
                        if (response.items[i].volumeInfo.industryIdentifiers[1].identifier!=null) {
                                isbn13=$('<div class="bisbn13">' + response.items[i].volumeInfo.industryIdentifiers[1].identifier  + '</div>');
                                isbn = $('<tr><td class="field-container2" id="field_isbn">ISBN: </td><td><input type="text" id="sc_isbn" value="' + 
                                response.items[i].volumeInfo.industryIdentifiers[0].identifier + '" name="isbn"></td></tr>');
                                isbn2 = response.items[i].volumeInfo.industryIdentifiers[1].identifier;
                       }
                    }
                }
                // Korte samenvatting
                if (response.items[i].searchInfo!=null) { 
                    if (response.items[i].searchInfo.textSnippet!=null) { 
                        snippet=$('<tr><td class="snippet">Samenvatting: </td><td>' + response.items[i].searchInfo.textSnippet.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,' ')  + '</td></tr>');  
                        snippet2 = response.items[i].searchInfo.textSnippet.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g,' ') ;
                }}
                // Thumbnail van de voorkant
                if (response.items[i].volumeInfo.imageLinks!=null) {
                    if (response.items[i].volumeInfo.imageLinks.thumbnail!=null) {
                    img=$('<tr><td colspan="2">' +
                            '<table class="extra"><tr> <td class="opm-img"><textarea class="opmerkingen" type="text" rows=8 name="opmerking"></textarea></td>' +
                                        '<td><img class="" src="'+response.items[i].volumeInfo.imageLinks.thumbnail+'"></td></tr>' +
                          '</table></td></tr>') ;  
                    img2 = $('<img class="" src="'+response.items[i].volumeInfo.imageLinks.thumbnail+'">');
                    img3 = response.items[i].volumeInfo.imageLinks.thumbnail;
                    }
                }
                // Link naar preview Google Books
                if (response.items[i].volumeInfo.previewLink!=null) {
                        meerlezen=$('<tr><td class="verderlezen">Lees meer: </td><td><a href="' + response.items[i].volumeInfo.previewLink + '">Lees meer</a></td></tr>') ;  
                        link2 = response.items[i].volumeInfo.previewLink;
                }
                // PublishedDate
                if (response.items[i].volumeInfo.publishedDate!=null) {
                        pdate=$('<tr><td>Published: </td><td class="bti"><input type="text" id="sc_ti" value="' + response.items[i].volumeInfo.publishedDate + '" name="publishedDate"></td></tr>') ;
                        pubdate2 = response.items[i].volumeInfo.publishedDate;
                }
    
                vorige=$('<button class="button green small-6">vorige</button>');
                volgende=$('<button class="button green small-6">volgende</button>');
    
                if (response.items[i].volumeInfo.subtitle!=null) {  
                        subtitle=$('<div class="subtitel">' + response.items[i].volumeInfo.subtitle  + '</div>'); 
                }
                if (response.items[i].volumeInfo.publisher!=null) {  
                        uitgever=$('<div class="uitgever">' + response.items[i].volumeInfo.publisher  + '</div>'); 
                        publisher2 = response.items[i].volumeInfo.publisher;
                }
                if (response.items[i].volumeInfo.pageCount!=null ) {  
                        pages=$('<div class="bpages">' + response.items[i].volumeInfo.pageCount  + ' pagina\'s</div>'); 
                        pages2 = response.items[i].volumeInfo.pageCount;
                } 
                if (response.items[i].volumeInfo.categories!=null ) { 
                        categ=$('<div class="bcategorie">' + response.items[i].volumeInfo.categories  + ' </div>'); 
                }
                if (response.items[i].volumeInfo.infoLink!=null) {
                    if (response.items[i].volumeInfo.infoLink!=null) {
                        url = response.items[i].volumeInfo.infoLink;
                        meerlezen=$('<a href="' + response.items[i].volumeInfo.infoLink + '">Lees meer</a>') ; 
                        link2 =  response.items[i].volumeInfo.infoLink;
                    }
                }
    
                $('#navigatie').empty();
                // $('#results').empty();
                // if (title!=0) {     title.appendTo('#results'); } 
                // if (author!=0) {    author.appendTo('#results'); } 
                // if (isbn!=0) {      isbn.appendTo('#results'); }
                // if (pdate!=0) {     pdate.appendTo('#results'); }
                // if (snippet!=0) {   snippet.appendTo('#results'); }
                // if (img!=0) {       img.appendTo('#results'); }
                // if (meerlezen!=0) { meerlezen.appendTo('#results'); }
    
                if (title2!=0) {
                    $('#zoektitel').hide();
                    $('#boekInfo').show();
                    $('#schrijver').empty().html("<font color='darkblue'>Auteur(s):</font> &nbsp;"+author2);
                    $('#boektitel').empty().html("<font color='darkblue'>Titel:</font> &nbsp;"+title2.substring(0,100));
                    $('#isbnnummer').empty().html("<font color='darkblue'>ISBN:</font> &nbsp;"+isbn2);
                    if (snippet2!=0)  $('#snippet').empty().html("<font color='darkblue'>Omschrijving:</font> &nbsp;"+snippet2);
                    if (img2==0) {
                        img2 = $('<img class="" src="https://via.placeholder.com/120x170">');
                    }
                    $('#plaatje').empty().html(img2);

                    $('#gboektitel').val(title2.substring(0,100));
                    $('#gboekauteur').val(author2);
                    $('#gboekisbn').val(isbn2);
                    $('#gboekpubjaar').val(pubdate2);
                    $('#gboekpublisher').val(publisher2);
                    $('#gboeklink').val(link2);
                    $('#gboekpages').val(pages2);
                    $('#gboeksnippet').val(snippet2);
                    $('#gboekimage').val(img3);

                    $('#zoeksubtitel').hide();
                    $('#bnieuwboek').show();
                    // img2.appendTo('#plaatje'); 
                    vorige.appendTo('#navigatie');
                    volgende.appendTo('#navigatie');
               }
    
            });
    
        }
        return false;
    
    });
    


    // var transferData = $("#transfer").val();
    // if (transferData > 0) {
    //     Cookies.set('id_user', transferData);
    // }
    // var uid = Cookies.get('id_user');
    // $.ajax({
    //     method: "POST",
    //     url: "assets/inc/___ajax.php",
    //     data: { data1: uid } 
    //     }).done(function(html) {						 
    //             console.log("ajax-function: Hallo, success at ");
    //     }).fail(function(html) {
    //             console.log("ajax-function: Hallo, fails");
    // });
    // $('#transferedData').html(uid);


 });
    